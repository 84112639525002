import { graphql } from "gatsby"
import { FluidObject } from "gatsby-image"
import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react"
import { urlHashFromText } from "../components/common/app.utils"
import SEO from "../components/common/seo"
import OldTicker from "../components/common/ticker"
import Ticker from "../components/common/ticker"
import {
  dropdownStyle,
  subDropdownStyle,
} from "../components/explore/dropdown.style"
import Style from "../components/explore/explore.style"
import FavoriteLocationTickerCard from "../components/explore/favoriteLocationTickerCard"
import {
  Filter,
  FilterCategory,
  isSingleValidValue,
  SimpleOption,
} from "../components/common/filters"
import ItineraryCard from "../components/explore/itineraryCard"
import MapBox from "../components/explore/map"
import TopLocationTickerCard from "../components/explore/topLocationTickerCard"
import Layout from "../components/layout/layout"
import ScrollDown from "../components/scrollDown"
import {
  ApiRetailer,
  isRetailer,
  isStation,
  SimpleRetailer,
  sortRetailers,
} from "../services/retailersService"
import { scrollToRef } from "../services/scrollService"
import { COLORS } from "../styles"
import { ValueType } from "react-select"
import {
  ParkingMapCard,
  RetailerMapCard,
  StationMapCard,
} from "../components/explore/mapCard"
import { ParkingLot, TransitStation } from "../components/explore/map.client"
import { Media } from "../Media"
import { useWindowSize } from "react-use"

const days = [
  { value: "all", label: "All" },
  { value: "Mon", label: "Monday" },
  { value: "Tue", label: "Tuesday" },
  { value: "Wed", label: "Wednesday" },
  { value: "Thu", label: "Thursday" },
  { value: "Fri", label: "Friday" },
  { value: "Sat", label: "Saturday" },
  { value: "Sun", label: "Sunday" },
]

type Subcategory = {
  id: string
  name: string
}
export interface Option {
  label: string
  value: string
  subcategories?: Subcategory[]
}

export interface Props {
  data: {
    allContentfulCategory: {
      edges: {
        node: {
          id: string
          name: string
          order: number
          subcategories: Subcategory[]
        }
      }[]
    }
    contentfulExplorePage: {
      name: string
      title?: string
      description: string
      slug: string
      publish: boolean
    }
    allContentfulItinerary: {
      edges: {
        node: {
          title: string
          creator: string
          image: {
            title: string
            fluid: FluidObject
          }
          excerpt: string
          description: {
            description: string
          }
          slug: string
          daysOpen: Array<string>
        }
      }[]
    }
    allContentfulRetailer: {
      edges: {
        node: ApiRetailer
      }[]
    }
    allContentfulTransitStation: {
      edges: {
        node: TransitStation
      }[]
    }
    allContentfulParkingLot: {
      edges: {
        node: ParkingLot
      }[]
    }
  }
}

const highlightRefs: any = {}

const getBaseNumberOfDisplayedArticles = (windowWidth: number): number => {
  if (windowWidth > 1744) {
    return 8
  }
  return 6
}

const ExplorePage: FunctionComponent<Props> = ({ data }) => {
  const {
    title,
    name,
    description,
    slug: explorePageSlug,
  } = data.contentfulExplorePage

  const [filterDay, setFilterDay] = useState<string>("all")
  const [filterCategory, setFilterCategory] = useState<string>("all")
  const [filterSubcategory, setFilterSubcategory] = useState<string>("all")
  const [subcategories, setSubcategories] = useState<Subcategory[]>([
    { id: "all", name: "All" },
  ])
  const [placeholder, setPlaceholder] = useState<string>(
    "No Subcategories Available"
  )
  const [highlightedElement, setHighlightedElement] = useState<
    SimpleRetailer | TransitStation | ParkingLot
  >()

  const itinerariesRef = useRef(null)

  useEffect(() => {
    if (
      isRetailer(highlightedElement) &&
      highlightRefs[`${highlightedElement.address}-${highlightedElement.name}`]
    ) {
      scrollToRef(
        highlightRefs[
          `${highlightedElement.address}-${highlightedElement.name}`
        ]
      )
    }
  }, [highlightedElement])

  // Categories are retrieved from Contentful
  const categories = useMemo(
    () =>
      [{ value: "all", label: "All" }].concat(
        data.allContentfulCategory.edges
          .filter(node => node.node.name !== "History")
          .map(node => ({
            value: node.node.name,
            label: node.node.name,
            subcategories:
              node.node.subcategories &&
              node.node.subcategories.map((subcategory: Subcategory) => ({
                value: subcategory.name,
                label: subcategory.name,
              })),
          }))
      ),
    [data]
  )

  const itineraries = useMemo(
    () =>
      data.allContentfulItinerary.edges
        .map(edge => edge.node)
        .filter(itinerary => !itinerary.title.toLowerCase().includes("dummy")),
    [data]
  )

  const allRetailers = useMemo(
    () =>
      data.allContentfulRetailer.edges
        .map(edge => edge.node)
        // do not simplify
        .filter(item => item.display !== false),
    [data]
  )

  const filteredRetailers = useMemo(
    () =>
      filterCategory === "all"
        ? allRetailers
        : allRetailers.filter(item => item.category[0].name === filterCategory),
    [filterCategory, allRetailers]
  )

  const filteredRetailersSub = useMemo(
    () =>
      filterSubcategory === "all"
        ? filteredRetailers
        : filteredRetailers.filter(
            item =>
              item.subcategory && item.subcategory[0].name === filterSubcategory
          ),
    [filterSubcategory, filteredRetailers]
  )
  const allRetailersAddresses = useMemo(
    () =>
      filteredRetailersSub.map(node => ({
        id: node.id,
        address: node.address,
        name: node.name,
        category: node.subcategory
          ? node.subcategory[0].name
          : node.category[0].name,
        slug: node.slug,
        exactLocation: node.exactLocation,
      })),
    [filteredRetailersSub]
  )

  // todo make a way better version
  allRetailers.map(
    item => (highlightRefs[`${item.address}-${item.name}`] = useRef())
  )

  const topLocations = useMemo(
    () =>
      [
        ...itineraries.map(item => ({
          name: item.title,
          heroImage: item.image,
          slug: item.slug,
          isItinerary: true,
          onlyInFifth: false,
          creator: "By " + item.creator,
        })),
        ...allRetailers
          .filter(
            item =>
              item.onlyInFifth ||
              item.category[0].name.toLowerCase() === "culture"
          )
          .map(item => ({
            name: item.name,
            heroImage: item.heroImage,
            slug: item.slug,
            isItinerary: false,
            onlyInFifth: item.onlyInFifth,
            creator:
              (item.subcategory &&
                item.subcategory[0] &&
                item.subcategory[0].name) ||
              item.category[0].name,
          })),
      ].slice(0, 5),
    [itineraries, allRetailers]
  )

  const favoriteLocations = useMemo(
    () => allRetailers.filter(item => item.favoriteLocation),
    [allRetailers]
  )

  const { width: windowWidth } = useWindowSize()
  const [setsToDisplay, setSetsToDisplay] = useState(1)

  const getItinerariesToDisplay = useCallback(() => {
    const filteredItineraries = data.allContentfulItinerary.edges
      .map(edge => edge.node)
      .filter(
        itinerary =>
          filterDay === "all" || itinerary.daysOpen.includes(filterDay)
      )

    const filteredAndSlicedItineraries = filteredItineraries.slice(
      0,
      getBaseNumberOfDisplayedArticles(windowWidth) * setsToDisplay
    )
    const areAllItinerariesDisplayed =
      filteredItineraries.length === filteredAndSlicedItineraries.length
    return { filteredAndSlicedItineraries, areAllItinerariesDisplayed }
  }, [data.allContentfulItinerary, windowWidth, setsToDisplay, filterDay])

  const stations = useMemo(
    () => data.allContentfulTransitStation.edges.map(edge => edge.node),
    [data.allContentfulTransitStation]
  )

  const parkings = useMemo(
    () => data.allContentfulParkingLot.edges.map(edge => edge.node),
    [data.allContentfulParkingLot]
  )

  const onHighlight = useCallback(
    (markerId: string) => {
      const retailer = filteredRetailers.find(
        element => element.address === markerId
      )
      if (retailer) {
        return setHighlightedElement(retailer)
      }
      const station = stations.find(station => station.id === markerId)
      if (station) {
        return setHighlightedElement(station)
      }
      const parking = parkings.find(parking => parking.id === markerId)
      if (parking) {
        return setHighlightedElement(parking)
      }
    },
    [filteredRetailers, stations, parkings]
  )

  const handleFilter = useCallback(
    (value: ValueType<SimpleOption>) => {
      if (isSingleValidValue(value)) {
        setFilterDay(value.value)
      }
    },
    [setFilterDay]
  )

  const handleFilterByCategory = useCallback((option: Option) => {
    if (option) {
      setFilterSubcategory("all")
      setFilterCategory(option.value)
    }
    if (option.subcategories) {
      setSubcategories(option.subcategories)
      setPlaceholder(`All ${option.value}`)
    } else {
      setSubcategories([])
      setPlaceholder("No Subcategories Available")
    }
  }, [])

  const handleFilterBySubcategory = useCallback((option: Option) => {
    if (option) {
      setFilterSubcategory(option.value)
    }
  }, [])

  const handleFilterBySubcategoryMobile = useCallback((option: Option) => {
    if (option) {
      if (typeof option.subcategories === "undefined") {
        setFilterCategory("all")
        setFilterSubcategory(option.value)
      } else {
        setFilterSubcategory("all")
        setFilterCategory(option.value)
      }
    }
  }, [])

  const favoriteLocationsTitle = "FIFTH AVENUE FAVORITES",
    filterCategoryTitle = "Our Interactive Map",
    favoriteLocationsTitleId = urlHashFromText(favoriteLocationsTitle)

  return (
    <Layout>
      <SEO title={title || name} description={description} />
      <Style.TopTicker.Container>
        <Ticker>
          {topLocations.map(element => (
            <TopLocationTickerCard element={element} key={element.slug} />
          ))}
        </Ticker>
      </Style.TopTicker.Container>
      <ScrollDown goToRef={itinerariesRef} />

      <Filter
        title="Itineraries"
        currentValue={filterDay}
        options={days}
        placeholder="Filter By Day"
        styles={dropdownStyle}
        onChange={handleFilter}
        backgroundColor={COLORS.PALE_GREY_2}
      />
      <Style.Itineraries.Container ref={itinerariesRef}>
        {getItinerariesToDisplay().filteredAndSlicedItineraries.map(
          ({ title, image, creator, excerpt, slug, daysOpen }, index) => {
            return (
              <ItineraryCard
                key={index}
                title={title}
                image={image}
                creator={creator}
                excerpt={excerpt}
                daysOpen={daysOpen}
                explorePageSlug={explorePageSlug}
                itinerarySlug={slug}
              />
            )
          }
        )}
        {!getItinerariesToDisplay().areAllItinerariesDisplayed && (
          <Style.Itineraries.SeeMoreButton
            icon="rightPlus"
            mode="button"
            onClick={() => setSetsToDisplay(currentNumber => currentNumber + 1)}
          >
            See More
          </Style.Itineraries.SeeMoreButton>
        )}
      </Style.Itineraries.Container>

      <FilterCategory
        title={filterCategoryTitle}
        backgroundColor={COLORS.BEIGE}
        elements={categories}
        filter={filterCategory}
        onChange={handleFilterByCategory}
        onChangeMobile={handleFilterBySubcategoryMobile}
        placeholder="Filter By Category"
        styles={dropdownStyle}
      />

      <Style.Map.root>
        <Style.Map.MapContainer>
          <MapBox
            elements={allRetailersAddresses}
            highlightedId={
              isRetailer(highlightedElement)
                ? highlightedElement.address
                : highlightedElement?.id
            }
            onHighlight={onHighlight}
            stations={stations}
            parkings={parkings}
          />
        </Style.Map.MapContainer>
        <Media greaterThanOrEqual="md">
          <Style.Map.RetailersContainer id={"RetailersContainer"}>
            {filterCategory !== "all" && subcategories.length !== 0 ? (
              <Style.Map.Dropdown
                closeMenuOnScroll={(e: Event) => e}
                options={subcategories}
                isSearchable={false}
                styles={subDropdownStyle}
                onChange={handleFilterBySubcategory}
                placeholder={placeholder}
                noOptionsMessage={() => "Please Select a Category"}
              />
            ) : (
              ""
            )}
            {filteredRetailersSub.sort(sortRetailers).map((retailer, index) => {
              let timer: number
              return (
                <RetailerMapCard
                  key={retailer.id}
                  retailer={retailer}
                  isHighlighted={
                    isRetailer(highlightedElement) &&
                    highlightedElement.address === retailer.address
                  }
                  isTop={index === 0}
                  reference={
                    highlightRefs[`${retailer.address}-${retailer.name}`]
                  }
                  onMouseLeave={() => clearTimeout(timer)}
                  onMouseEnter={() =>
                    (timer = setTimeout(() => {
                      setHighlightedElement(retailer)
                    }, 1500))
                  }
                />
              )
            })}
          </Style.Map.RetailersContainer>
        </Media>
        <Media lessThan="md">
          {isRetailer(highlightedElement) &&
            highlightedElement.address != "" &&
            (filteredRetailersSub.filter(
              e => e.address === highlightedElement.address
            ).length === 1 ? (
              <Style.Map.SingleRetailerContainer
                key={highlightedElement.id}
                ref={
                  highlightRefs[
                    `${highlightedElement.address}-${highlightedElement.name}`
                  ]
                }
              >
                <RetailerMapCard retailer={highlightedElement} />
              </Style.Map.SingleRetailerContainer>
            ) : (
              <Style.Map.MultipleRetailerContainer.root
                ref={
                  highlightRefs[
                    `${highlightedElement.address}-${highlightedElement.name}`
                  ]
                }
              >
                <Style.Map.MultipleRetailerContainer.retailersCount>
                  {
                    filteredRetailersSub.filter(
                      e => e.address === highlightedElement.address
                    ).length
                  }{" "}
                  PLACES to visit here
                </Style.Map.MultipleRetailerContainer.retailersCount>
                <Style.Map.MultipleRetailerContainer.ticker>
                  <OldTicker lightTheme manual>
                    {filteredRetailersSub
                      .filter(e => e.address === highlightedElement.address)
                      .map(e => (
                        <Style.Map.SingleRetailerContainer
                          key={e.address}
                          ref={highlightRefs[`${e.address}-${e.name}`]}
                        >
                          <RetailerMapCard retailer={e} />
                        </Style.Map.SingleRetailerContainer>
                      ))}
                  </OldTicker>
                </Style.Map.MultipleRetailerContainer.ticker>
              </Style.Map.MultipleRetailerContainer.root>
            ))}
          {!isRetailer(highlightedElement) && highlightedElement && (
            <Style.Map.SingleRetailerContainer key={highlightedElement.id}>
              {isStation(highlightedElement) ? (
                <StationMapCard station={highlightedElement} />
              ) : (
                <ParkingMapCard parking={highlightedElement} />
              )}
            </Style.Map.SingleRetailerContainer>
          )}
        </Media>
      </Style.Map.root>
      {favoriteLocations && favoriteLocations.length !== 0 && (
        <>
          <Style.FavoriteTicker.Title id={favoriteLocationsTitleId}>
            {favoriteLocationsTitle}
          </Style.FavoriteTicker.Title>
          <Style.FavoriteTicker.Container>
            <Ticker>
              {favoriteLocations.map(element => {
                const address = element.addressMiniatureIndication
                  ? element.addressMiniatureIndication.split(" ")
                  : []
                return (
                  <FavoriteLocationTickerCard
                    element={element}
                    address={address}
                    key={element.slug}
                  />
                )
              })}
            </Ticker>
          </Style.FavoriteTicker.Container>
        </>
      )}
    </Layout>
  )
}

export default ExplorePage

export const query = graphql`
  query ExplorePageQuery {
    allContentfulCategory(filter: { name: { ne: "History" } }) {
      edges {
        node {
          id
          name
          order
          subcategories {
            id
            name
          }
        }
      }
    }
    contentfulExplorePage {
      name
      title
      slug
      description
    }
    allContentfulRetailer(filter: { showOnMaps: { ne: false } }) {
      edges {
        node {
          id
          display
          name
          address
          addressMiniatureIndication
          onlyInFifth
          favoriteLocation
          tips {
            tips
          }
          heroImage {
            title
            fluid(quality: 90, maxWidth: 1000) {
              ...GatsbyContentfulFluid
            }
          }
          slug
          category {
            name
          }
          subcategory {
            name
          }
          exactLocation {
            lat
            lon
          }
        }
      }
    }
    allContentfulItinerary(filter: { title: { ne: "Dummy content" } }) {
      edges {
        node {
          image {
            title
            fluid(quality: 90, maxWidth: 1000) {
              ...GatsbyContentfulFluid
            }
          }
          title
          creator
          openingHours
          excerpt
          description {
            description
          }
          daysOpen
          slug
        }
      }
    }
    allContentfulTransitStation(
      filter: { category: { eq: "Subway Station" } }
    ) {
      edges {
        node {
          id
          name
          transitLines {
            id
            name
            logo {
              file {
                url
              }
            }
          }
          category
          location {
            lat
            lon
          }
        }
      }
    }
    allContentfulParkingLot {
      edges {
        node {
          id
          name
          image {
            title
            fluid(quality: 90) {
              ...GatsbyContentfulFluid
            }
          }
          addressToShow
          location {
            lat
            lon
          }
        }
      }
    }
  }
`
