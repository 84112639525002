export function daysToBoolean(days: Array<string>): Array<boolean> {
  const sorter = {
    mon: 1,
    tue: 2,
    wed: 3,
    thu: 4,
    fri: 5,
    sat: 6,
    sun: 7,
  }

  days = days.sort(function sortByDay(a, b) {
    const day1 = a.toLowerCase()
    const day2 = b.toLowerCase()
    return sorter[day1] - sorter[day2]
  })

  const res = []
  const refDays = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"]
  for (let j = 0, i = 0; j < refDays.length; j++, i++) {
    if (days[i] === refDays[j]) {
      res[j] = true
    } else {
      res[j] = false
      i--
    }
  }
  return res
}
