import Style from "./scrollDown.style"
import React from "react"
import { scrollToRef } from "../services/scrollService"

interface Props {
  goToRef: any
}

const ScrollDown = ({ goToRef }: Props) => (
  <Style.ScrollDownContainer>
    <Style.ScrollDown onClick={() => scrollToRef(goToRef)}>
      <Style.ScrollDownIcon />
    </Style.ScrollDown>
  </Style.ScrollDownContainer>
)

export default ScrollDown
