import styled from "styled-components"
import { MOBILE_MAX_WIDTH } from "../../styles"
import { H2, H4, S1 } from "../common/text"
import { AutoLink } from "../link"

const Style = {
  Element: styled.div`
    width: 690px;
    margin-right: 2%;
    height: fit-content;
    @media screen and (max-width: ${MOBILE_MAX_WIDTH}px) {
      width: 346px;
    }
  `,
  ImageHolder: styled(AutoLink)`
    display: flex;
    flex-direction: row;
  `,
  Name: styled(H4)`
    padding-top: 5px;
    padding-bottom: 20px;
    @media screen and (max-width: ${MOBILE_MAX_WIDTH}px) {
      padding-top: 10px;
      padding-bottom: 10px;
    }
  `,
  Address: styled.div`
    display: flex;
    flex-direction: column;
    align-items: left;
    margin-left: 10px;
  `,
  AddressElement: styled(H2)`
    display: flex;
    flex-direction: column;
    margin-bottom: 18px;
    text-transform: uppercase;
    @media screen and (max-width: ${MOBILE_MAX_WIDTH}px) {
      margin-bottom: 10px;
    }
  `,
  Category: styled(S1)`
    margin-top: 15px;
  `,
}

export default Style
