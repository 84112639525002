import styled from "styled-components"
import { COLORS, MOBILE_MAX_WIDTH } from "../../styles"
import { AutoLink } from "../link"
import Img from "gatsby-image"
import { H5, S1, T1 } from "../common/text"
import { CTA } from "../common/cta"

export default {
  Container: styled(AutoLink)`
    display: flex;
    flex-direction: column;
    background-color: ${COLORS.AQUA_HAZE};
    align-items: center;
    width: 382px;
    cursor: pointer;
    &:hover {
      background-color: ${COLORS.PALE_GREY_2};
    }
    @media screen and (max-width: ${MOBILE_MAX_WIDTH}px) {
      width: 318px;
      margin-bottom: 8px;
    }
  `,
  BottomContainer: styled.div`
    position: relative;
    padding: 0px 20px 30px 20px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
  `,

  Image: styled(Img)`
    object-fit: cover;
    width: 100%;
    height: 235px;
    @media screen and (max-width: ${MOBILE_MAX_WIDTH}px) {
      height: 215px;
    }
  `,
  Name: styled(H5)`
    margin-top: 30px;
  `,
  Creator: styled(S1)`
    margin-top: 8px;
  `,
  Description: styled(T1)`
    padding: 8px 30px 20px 30px;
  `,
  Date: styled(S1)`
    position: absolute;
    top: -13.5px;
    left: 0px;
    right: 0px;
    padding: 6px 9px;
    width: fit-content;
    height: 27px;
    margin: 0 auto;
    background-color: ${COLORS.HOLLY_GREEN};
  `,
  LearnMore: styled(CTA)``,
}
