import React, { FunctionComponent } from "react"
import { CTA } from "../common/cta"
import Style from "./favoriteLocationTickerCard.style"
import TickerStyle from "../common/ticker.style"
import { ApiRetailer } from "../../services/retailersService"

interface FavoriteLocationTickerCardProps {
  element: ApiRetailer
  address: string[]
}

const FavoriteLocationTickerCard: FunctionComponent<FavoriteLocationTickerCardProps> = ({
  element,
  address,
}) => {
  return (
    <Style.Element>
      <TickerStyle.BottomCarouselImages.HighlightSpan />
      <Style.ImageHolder to={element.slug}>
        <TickerStyle.BottomCarouselImages.TickerImage
          fluid={element.heroImage.fluid}
          alt={element.heroImage.title}
        />
        <Style.Address>
          {address.map((section: string, index: number) => (
            <Style.AddressElement key={index}>
              {section.split("").map((str, index) => (
                <div key={index + str}>{str}</div>
              ))}
            </Style.AddressElement>
          ))}
        </Style.Address>
      </Style.ImageHolder>
      <Style.Category>
        {(element.subcategory && element.subcategory[0].name) ||
          element.category[0].name}
      </Style.Category>
      <Style.Name>{element.name}</Style.Name>
      <CTA to={element.slug} id="ctaId">
        Learn More
      </CTA>
    </Style.Element>
  )
}

export default FavoriteLocationTickerCard
