import styled from "styled-components"
import { COLORS, MOBILE_MAX_WIDTH } from "../styles"
import DownPurpleArrowIcon from "../images/arrow-down.svg"

export default {
  ScrollDownContainer: styled.div`
    height: 70px; // 25px + 45px of padding
    display: flex;
    justify-content: center;
    width: 100%;
    padding-top: 45px;
    background-color: ${COLORS.HOLLY_GREEN};

    @media screen and (max-width: ${MOBILE_MAX_WIDTH}px) {
      height: 25px;
      padding-top: 0px;
    }
  `,
  ScrollDown: styled.div`
    display: flex;
    justify-content: center;
    width: 50px;
    color: ${COLORS.WHITE};
    background-color: ${COLORS.BOTTLE_GREEN};
    cursor: pointer;
    align-items: center;
    @media screen and (max-width: ${MOBILE_MAX_WIDTH}px) {
      width: 100%;
    }
  `,
  ScrollDownIcon: styled(DownPurpleArrowIcon)`
    width: 1.2rem;
    fill: ${COLORS.WHITE};
    @media screen and (max-width: ${MOBILE_MAX_WIDTH}px) {
      width: 1.8rem;
    }
  `,
}
