import styled from "styled-components"
import { MOBILE_MAX_WIDTH, COLORS, FONTS } from "../../styles"
import { H4, S1 } from "../common/text"

const Style = {
  ItineraryImageContainer: styled.div`
    display: flex;
    flex-direction: column;
    flex-grow: 5;
    height: 100%;
    max-height: 90vh;
    overflow: hidden;
    @media screen and (max-width: ${MOBILE_MAX_WIDTH}px) {
      height: 60vh;
    }
  `,
  ItineraryBottomContainer: styled.div`
    display: flex;
    flex-direction: column;
  `,
  Element: styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    width: 790px;
    margin-right: 1rem;
    padding-top: 20px;
    height: 530px;
    @media screen and (max-width: ${MOBILE_MAX_WIDTH}px) {
      width: 80%;
      height: 76vh;
      padding-top: 0px;
    }
  `,
  Name: styled(H4)`
    margin-top: 5px;
    @media screen and (max-width: ${MOBILE_MAX_WIDTH}px) {
      margin-top: 10px;
    }
  `,
  Badge: styled(S1)`
    background-color: ${COLORS.LIGHT_BLUE_GREY};
    width: fit-content;
    margin-top: 20px;
    padding: 6px 9px;
  `,
  BadgeOnlyOnFifth: styled.div`
    background-color: ${COLORS.BLUE_CHALK_VIOLET};
    width: fit-content;
    margin-top: 20px;
    padding: 6px 9px;
  `,
  Creator: styled(S1)`
    width: 320px;
    height: 15px;
    margin-top: 5px;
    margin-bottom: 20px;
  `,
}

export default Style
