import React, { FunctionComponent } from "react"
import Style from "./itineraryCard.styles"
import { daysToBoolean } from "./explore.utils"
import { FluidObject } from "gatsby-image"
import { COLORS } from "../../styles"

export interface ItineraryCardProps {
  image: {
    fluid: FluidObject
    title: string
  }
  title: string
  creator: string
  excerpt: string
  daysOpen: Array<string>
  explorePageSlug: string
  itinerarySlug: string
}

const days = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"]

export function getReducedDaysOpen(daysOpen: Array<string>): string {
  const schedule = daysToBoolean(daysOpen)
  return days
    .map((day, index) => (schedule[index] ? day : " "))
    .join("")
    .trim()
    .split(" ")
    .filter(period => period !== "")
    .map(period =>
      period.length === 3
        ? period
        : `${period.substr(0, 3)} - ${period.substr(-3)}`
    )
    .join(" • ")
    .replace("Sat - Sun", "Weekends")
}

const ItineraryCard: FunctionComponent<ItineraryCardProps> = ({
  title,
  image,
  creator,
  excerpt,
  daysOpen,
  explorePageSlug,
  itinerarySlug,
}) => {
  const slug = `/${explorePageSlug}/${itinerarySlug}`

  return (
    <Style.Container to={slug}>
      <Style.Image fluid={image.fluid} alt={image.title} />

      <Style.BottomContainer>
        <Style.Date $textColor={COLORS.WHITE}>
          {getReducedDaysOpen(daysOpen)}
        </Style.Date>

        <Style.Name>{title}</Style.Name>
        <Style.Creator>{`By ${creator}`}</Style.Creator>
        <Style.Description $textColor={COLORS.CHARCOAL}>
          {excerpt}
        </Style.Description>
        <Style.LearnMore to={slug}>View Itinerary</Style.LearnMore>
      </Style.BottomContainer>
    </Style.Container>
  )
}

export default ItineraryCard
