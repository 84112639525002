import React, { FunctionComponent } from "react"
import { CTA } from "../common/cta"
import Style from "./topLocationTickerCard.style"
import TickerStyle from "../common/ticker.style"
import { FluidObject } from "gatsby-image"

interface TopLocationTickerCardProps {
  element: {
    name: string
    heroImage: {
      title: string
      fluid: FluidObject
    }
    slug: string
    isItinerary: boolean
    onlyInFifth: boolean
    creator: string
  }
}

const TopLocationTickerCard: FunctionComponent<TopLocationTickerCardProps> = ({
  element,
}) => {
  return (
    <Style.Element key={element.name}>
      <Style.ItineraryImageContainer>
        <TickerStyle.TopCarouselImages.HighlightSpan />
        <TickerStyle.TopCarouselImages.TickerImage
          fluid={element.heroImage.fluid}
          alt={element.heroImage.title}
        />
      </Style.ItineraryImageContainer>
      <Style.ItineraryBottomContainer>
        {element.isItinerary && <Style.Badge>Itinerary</Style.Badge>}
        {!element.isItinerary && element.onlyInFifth && (
          <Style.BadgeOnlyOnFifth>Only on fifth</Style.BadgeOnlyOnFifth>
        )}
        <Style.Name $textColor="white">{element.name}</Style.Name>
        <Style.Creator $textColor="white">{element.creator}</Style.Creator>
        <CTA to={`/itineraries/${element.slug}`} id="ctaId" color="white">
          View Itinerary
        </CTA>
      </Style.ItineraryBottomContainer>
    </Style.Element>
  )
}

export default TopLocationTickerCard
